<template>
  <!--begin::Wrapper-->
  <h1>Şirketler</h1>
  <div class="row g-6 g-xl-9 mt-3">
    <div
      class="col-md-4 col-xl-4"
      v-for="(tenantEstate, index) in tenantEstates"
      :key="index"
    >
      <a
        @click="changeTenant(tenantEstate)"
        class="
          card
          border border-2 border-gray-300 border-hover
          rounded
          shadow-sm
        "
      >
        <!--begin::Card header-->
        <div class="card-header border-0 pt-9">
          <!--begin::Card Title-->
          <div class="card-title m-0">
            <!--begin::Avatar-->
            <div class="symbol symbol-50px w-50px bg-light">
              <img
                src="/media/icons/duotune/general/gen001.svg"
                alt="image"
                class="p-3"
              />
            </div>
            <!--end::Avatar-->
          </div>
          <!--end::Car Title-->

          <!--begin::Card toolbar-->
          <div class="card-toolbar">
            <span class="badge badge-light-primary fw-bolder me-auto px-4 py-3"
              >{{tenantEstate.tenantRole.data.attributes.name}}</span
            >
          </div>
          <!--end::Card toolbar-->
        </div>
        <!--end:: Card header-->

        <!--begin:: Card body-->
        <div class="card-body p-9">
          <!--begin::Name-->
          <div class="fs-3 fw-bolder text-dark">{{tenantEstate.name}}</div>
          <!--end::Name-->

          <!--begin::Description-->
          <p class="text-gray-500 fw-bold fs-7 mt-3 mb-7">
            Şirket açıklaması...
          </p>
          <!--end::Description-->
        </div>
        <!--end:: Card body-->
      </a>
    </div>

    <!-- Yeni Şirket Oluştur -->

    <div class="col-md-4 col-xl-4">
      <a
        class="
          card
          border border-2 border-gray-300 border-hover
          rounded
          shadow-sm
        "
      >
      <router-link to="/createCompany">
        <!--begin::Card header-->
        <div class="card-header border-0 pt-9">
          <!--begin::Card Title-->
          <div class="card-title m-0">
            <!--begin::Avatar-->
            <div class="symbol symbol-50px w-50px bg-light">
              <img
                src="/media/icons/duotune/abstract/abs011.svg"
                alt="image"
                class="p-3"
              />
            </div>
            <!--end::Avatar-->
          </div>
          <!--end::Car Title-->
        </div>
        <!--end:: Card header-->

        <!--begin:: Card body-->
        <div class="card-body p-9">
          <!--begin::Name-->
          <div class="fs-3 fw-bolder text-dark">{{ $t("companies.createCompany") }}</div>
          <!--end::Name-->
          <!--begin::Description-->
          <p class="text-gray-500 fw-bold fs-7 mt-3 mb-7">
            {{ $t("companies.createCompanyDesc") }}
          </p>
          <!--end::Description-->
        </div>
        <!--end:: Card body-->
      </router-link>
      </a>
    </div>
  </div>

  <!--end::Wrapper-->
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { TenantEstateModel } from "@/domain/user/tenantEstate/model/TenantEstateModel";
import { ChangeTenantEstateModel } from "@/domain/auth/changeTenantEstate/model/ChangeTenantEstateModel";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";

export default defineComponent({
  name: "name",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const userController = store.state.ControllersModule.userController;
    const router = useRouter();
    const changedTenant = ref<number>(0);
      const swalNotification = new SwalNotification();

    const tenantEstates = ref<TenantEstateModel[]>([]);
    
    const getUserInfo = () => {
      userController
        .aboutUser()
        .then((response) => {
          if (response.isSuccess) {

            tenantEstates.value = response.getValue().tenantEstates
            
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const changeTenant = (tenantEstate: TenantEstateModel) => {
      const changeTenantEstateModel: ChangeTenantEstateModel = {
        tenantEstateId: Number(tenantEstate.id),
      };

      userController
        .changeTenantEstate(changeTenantEstateModel)
        .then((response) => {
          if (response.isSuccess) {
            window.location.href = "/dashboard";
            /*
                        location.reload();
            router.push({ name: "dashboard" });
            */

            /*
            changedTenant.value += 1;
              store.dispatch("changeTenant", changedTenant.value);

            router.push({ name: "dashboard" });
*/
          }
        });
    };

    onMounted(() => {
      getUserInfo();
    });

    return {
      tenantEstates,
      changeTenant,
    };
  },
});
</script>
